var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "About" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("TransitionSlide", [
            _c("div", { staticClass: "text" }, [
              _c("h1", [_vm._v("Quienes somos")])
            ])
          ])
        ],
        1
      ),
      _c("TransitionSlide", { attrs: { side: "bottom" } }, [
        _c("div", { staticClass: "content" }, [
          _c("p", [
            _vm._v(
              " (NOMBRE) es una herramienta online para producir estados de información no financiera y memorias de sostenibilidad diseñadas para dar respuesta a los requerimientos de la Ley 11/2018 de Información No Financiera. "
            )
          ]),
          _c("p", [
            _vm._v(
              " La herramienta ha sido elaborada por el Grupo de Investigación ERGO, de la Universidad de Burgos, tomando como base su Guía para la Aplicación de la Ley 11/2018 de Información No Financiera. "
            )
          ]),
          _c("p", [_vm._v("Esta herramienta busca ayudar a:")]),
          _c("ul", [
            _c("li", [
              _c("b", [_vm._v("Empresas")]),
              _vm._v(
                ", al facilitarles la elaboración de informes no financieros, tanto aquellas compañías obligadas a reportar esta información, como aquellas que decidan hacerlo de forma voluntaria. "
              )
            ]),
            _c("li", [
              _c("b", [_vm._v("Consultores")]),
              _vm._v(
                ", al permitirles crear carteras de clientes a los que asesoran en la elaboración de información no financiera. "
              )
            ]),
            _c("li", [
              _c("b", [_vm._v("Aseguradores")]),
              _vm._v(
                ", al proporcionar una herramienta que estructura el contenido del informe no financiero basada en estándares reconocidos de información de sostenibilidad, y permitiendo disponer de evidencia por parte de la empresa de la información reportada. "
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }