<template>
  <div class="About">
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1>Quienes somos</h1>
        </div>
      </TransitionSlide>
    </div>

    <TransitionSlide side="bottom">
      <div class="content">
        <p>
          (NOMBRE) es una herramienta online para producir estados de información no financiera y memorias de sostenibilidad diseñadas para dar respuesta a los requerimientos de la Ley 11/2018 de Información No Financiera.
        </p>

        <p>
          La herramienta ha sido elaborada por el Grupo de Investigación ERGO, de la Universidad de Burgos, tomando como base su Guía para la Aplicación de la Ley 11/2018 de Información No Financiera.
        </p>

        <p>Esta herramienta busca ayudar a:</p>

        <ul>
          <li>
            <b>Empresas</b>, al facilitarles la elaboración de informes no financieros, tanto aquellas compañías obligadas a reportar esta información, como aquellas que decidan hacerlo de forma voluntaria.
          </li>
          <li>
            <b>Consultores</b>, al permitirles crear carteras de clientes a los que asesoran en la elaboración de información no financiera.
          </li>
          <li>
            <b>Aseguradores</b>, al proporcionar una herramienta que estructura el contenido del informe no financiero basada en estándares reconocidos de información de sostenibilidad, y permitiendo disponer de evidencia por parte de la empresa de la información reportada.
          </li>
        </ul>
      </div>
    </TransitionSlide>
  </div>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

export default {
  components: {
    TransitionSlide,
  },
};
</script>

<style lang="scss" scoped>
.About {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 200px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }
  }

  .content {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 2rem;
    display: grid;
    gap: $spacing-s;


    ul {
      padding: $spacing-xs;
      padding-left: $spacing-xl;
      list-style: initial;

      li {
        margin-bottom: $spacing-xs;

        b{
          font-weight: 500;
          color: $color-primary-darker;
        }

        &::marker {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
